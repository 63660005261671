<template>
  <div class="errorTip" v-show="status">
    <div class="dialogView flex_g vertical  flex_column">
      <p class="flex_g flex_endX">
        <img @click="close" src="@/assets/images/close_gray.png" alt />
      </p>
      <p class="flex_g flex_center">
        <!-- <img v-if="type==1" src="@/assets/images/errorTip.png" alt />
        <img v-if="type==2" src="@/assets/images/warn_icon.png" alt /> -->
        <span>{{content}}</span>
      </p>
      <p @click="close">好的</p>
    </div>
  </div>
  <!-- 父组件使用 -->
  <!-- <div is='errorTip' ref="errorTip"></div> -->
</template>

<script>
export default {
  name: "errorTip",
  data() {
    return {
      status: false, //是否展示
      content: "确认要删除么？",
      type:1,
    };
  },
  methods: {
    open(content,type) {
      this.status = true;
      if (content != null && content != undefined) {
        this.content = content;
      }
      this.type=type
    },
    close() {
      this.status = false;
      this.$emit("cancle");
    }
  }
};
</script>

<style lang="scss" scoped>
.errorTip {
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 10000;
  .dialogView {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background: rgba(255, 255, 255, 0.6);
    box-shadow: 0px 4px 19px 2px rgba(77, 75, 76, 0.2);
    width: 320px;
    min-height: 160px;
    border-radius: 10px;
    text-align: center;
    p:nth-child(1) {
      width: 100%;
      text-align: right;
      margin-top: 1.5rem;
      padding-bottom: 2rem;
      img {
        width: 0.8rem;
         height: 0.8rem;
        padding-right: 1rem;
      }
    }
    p:nth-child(2) {
      color: #333;
      font-size: 1.1rem;
      line-height: 1.5rem;
      width: 80%;
      text-align: center;
      padding-bottom: 2rem;
      img {
        width: 1.2rem;
        height: 1.2rem;
        padding-right: 0.5rem;
        flex-shrink: 0;
        padding-top: 0.2rem;
      }
    }
    p:nth-child(3) {
      color: #fff;
      border-radius: 5px;
      width: 75px;
      text-align: center;
      line-height: 30px;
      font-size: 1rem;
      background: #336ff1;
      cursor: pointer;
      margin-bottom: 1.5rem;
    }
  }
}
</style>