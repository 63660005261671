import http from '../http';
let projectApi = {}
//获取项目列表
const getProjectList = function (keywords, pageNo) {
    const opts = {
        url: "/iot/api/pc/project/list",
        params: {
            keywords,
            pageNo
        },
        contentType: 'application/x-www-form-urlencoded',
    }
    return new Promise((resolve, reject) => {
        http.post(opts).then(res => {
            resolve(res);
        })
            .catch(err => {
                reject(err);
            })
    });
};
//获取项目详情
const getProjectInfo = function (iotProjectCode) {
    const opts = {
        url: "/iot/api/pc/project/info",
        params: {
            iotProjectCode
        },
        contentType: 'application/x-www-form-urlencoded',
    }
    return new Promise((resolve, reject) => {
        http.post(opts).then(res => {
            resolve(res);
        })
            .catch(err => {
                reject(err);
            })
    });
};
//获取行业列表
const getIndustryList = function (pageNo) {
    const opts = {
        url: "/iot/api/pc/industry/list",
        params: {
            pageNo
        },
        contentType: 'application/x-www-form-urlencoded',
    }
    return new Promise((resolve, reject) => {
        http.post(opts).then(res => {
            resolve(res);
        })
            .catch(err => {
                reject(err);
            })
    });
};
//获取场景列表
const getSenceList = function (pageNo, industryId) {
    const opts = {
        url: "/iot/api/pc/sence/list",
        params: {
            pageNo,
            industryId
        },
        contentType: 'application/x-www-form-urlencoded',
    }
    return new Promise((resolve, reject) => {
        http.post(opts).then(res => {
            resolve(res);
        })
            .catch(err => {
                reject(err);
            })
    });
};
//获取产品应用模版列表
const getIotProductAppTemplateList = function (pageNo, industryCode, sceneCode) {
    const opts = {
        url: "/iot/api/pc/product/app/template/list",
        params: {
            pageNo,
            industryCode,
            sceneCode
        },
        contentType: 'application/x-www-form-urlencoded',
    }
    return new Promise((resolve, reject) => {
        http.post(opts).then(res => {
            //后期优化
            resolve(res);
        })
            .catch(err => {
                reject(err);
            })
    });
};
//编辑项目 1 新增 2 编辑 3 删除 
const editProjectInfo = function (data) {
    const opts = {
        url: "/iot/api/pc/project/edit",
        params: data,
        contentType: 'application/x-www-form-urlencoded',
    }
    return new Promise((resolve, reject) => {
        http.post(opts).then(res => {
            resolve(res);
        })
            .catch(err => {
                reject(err);
            })
    });
};
//获取项目授权情况
const getProjectAuthInfo = function (iotProjectCode) {
    const opts = {
        url: "/iot/api/pc/project/auth",
        params: {
            iotProjectCode
        },
        contentType: 'application/x-www-form-urlencoded',
    }
    return new Promise((resolve, reject) => {
        http.post(opts).then(res => {
            resolve(res);
        })
            .catch(err => {
                reject(err);
            })
    });
};
//获取项目服务api列表
const getProjectAppApiList = function (iotProjectCode, pageNo) {
    const opts = {
        url: "/iot/api/pc/project/app/api/list",
        params: {
            iotProjectCode,
            pageNo
        },
        contentType: 'application/x-www-form-urlencoded',
    }
    return new Promise((resolve, reject) => {
        http.post(opts).then(res => {
            resolve(res);
        })
            .catch(err => {
                reject(err);
            })
    });
};
//获取项目服务api详情
const getProjectAppApiInfo = function (apiCode) {
    const opts = {
        url: "/iot/api/pc/project/app/api/info",
        params: {
            apiCode
        },
        contentType: 'application/x-www-form-urlencoded',
    }
    return new Promise((resolve, reject) => {
        http.post(opts).then(res => {
            resolve(res);
        })
            .catch(err => {
                reject(err);
            })
    });
};
//项目服务api编辑 editType 1 新增 3 删除
const editProjectAppApiInfo = function (data) {
    const opts = {
        url: "/iot/api/pc/project/app/api/edit",
        params: data,
        contentType: 'application/x-www-form-urlencoded',
    }
    return new Promise((resolve, reject) => {
        http.post(opts).then(res => {
            resolve(res);
        })
            .catch(err => {
                reject(err);
            })
    });
};
//获取用户项目授权应用设备功能数据列表
const getProjectFunctionDataList = function (data) {
    const opts = {
        url: "/iot/api/pc/app/project/funcation/data/list",
        params: data,
        contentType: 'application/x-www-form-urlencoded',
    }
    return new Promise((resolve, reject) => {
        http.post(opts).then(res => {
            resolve(res);
        })
            .catch(err => {
                reject(err);
            })
    });
};
//获取用户项目授权设备列表
const getProjectDeviceList = function (iotProjectCode, pageNo, keywords) {
    const opts = {
        url: "/iot/api/pc/project/device/list",
        params: {
            iotProjectCode,
            pageNo,
            keywords,
        },
        contentType: 'application/x-www-form-urlencoded',
    }
    return new Promise((resolve, reject) => {
        http.post(opts).then(res => {
            resolve(res);
        })
            .catch(err => {
                reject(err);
            })
    });
};
//获取用户项目授权设备详情
const getProjectDeviceInfo = function (iotProjectDeviceCode) {
    const opts = {
        url: "/iot/api/pc/project/device/info",
        params: {
            iotProjectDeviceCode,

        },
        contentType: 'application/x-www-form-urlencoded',
    }
    return new Promise((resolve, reject) => {
        http.post(opts).then(res => {
            resolve(res);
        })
            .catch(err => {
                reject(err);
            })
    });
};
//编辑用户项目授权设备
const editProjectDeviceInfo = function (data) {
    const opts = {
        url: "/iot/api/pc/project/device/edit",
        params: data,
        contentType: 'application/x-www-form-urlencoded',
    }
    return new Promise((resolve, reject) => {
        http.post(opts).then(res => {
            resolve(res);
        })
            .catch(err => {
                reject(err);
            })
    });
};
export default projectApi = {
    getProjectList,
    getProjectInfo,
    getIndustryList,
    getSenceList,
    getIotProductAppTemplateList,
    editProjectInfo,
    getProjectAuthInfo,
    getProjectAppApiList,
    getProjectAppApiInfo,
    editProjectAppApiInfo,
    getProjectFunctionDataList,
    getProjectDeviceList,
    getProjectDeviceInfo,
    editProjectDeviceInfo,
}