<template>
    <div class="rightBarView">
        <vue-custom-scrollbar class="scroll-right-area" :settings="settings">
            <div class="articleView">
                <div class="captionView flex_g vertical between">
                    <div class="captionName">产品快讯</div>
                    <div class="captionMore flex_g vertical">
                        更多
                        <i class="iconfont icon-jiantouyou"></i>
                    </div>
                </div>
                <vue-custom-scrollbar class="scroll-article-area" :settings="settings">
                    <div class="articleItem flex_g vertical between" v-for="(item, index) of 10" :key="index">
                        <div class="articleName">【产品发布】3.0升级新功能…</div>
                        <i class="iconfont icon icon-jiantouyou"></i>
                    </div>
                </vue-custom-scrollbar>
            </div>
            <div class="righBanner"> </div>
            <div class="caseView">
                <div class="captionView flex_g vertical between">
                    <div class="captionName">方案推荐</div>
                    <div class="captionMore ">
                        更多  <i class="iconfont icon-jiantouyou"></i>
                    </div>
                </div>
                <div class="caseCategory flex_g vertical ">
                    <p @click="changeCaseCategory(1)"
                        :style="caseType == 1 ? 'color:rgba(51, 111, 241, 1);font-size:1rem;font-weight:bold' : ''">热门</p>
                    <div class="line"></div>
                    <p @click="changeCaseCategory(2)"
                        :style="caseType == 2 ? 'color:rgba(51, 111, 241, 1);font-size:1rem;font-weight:bold' : ''">最新</p>
                </div>
                <div class="caseSwiper">
                    <el-carousel height="22rem" indicator-position="outside" arrow="never">
                        <el-carousel-item v-for="(item, index) in swiperList" :key="index">
                            <div class="bannerList flex_g flex_wrap ">
                                <div class="bannerItem" v-for="(bItem, bIndex) of item.bannerList" :key="bIndex">
                                    <div class="bannerImg"></div>
                                    <div class="bannerName">解决方案解决方案解决方案解决方案</div>
                                </div>
                            </div>
                        </el-carousel-item>
                    </el-carousel>
                </div>
            </div>
            <div class="productView">
                <div class="captionView flex_g vertical between">
                    <div class="captionName">产品</div>
                    <div class="captionMore ">
                        更多  <i class="iconfont icon-jiantouyou"></i>
                    </div>
                </div>
                <div class="productList">
                    <div class="productItem" v-for="(item, index) of 5" :key="index"></div>
                </div>
            </div>

        </vue-custom-scrollbar>
    </div>
</template>

<script>
import vueCustomScrollbar from "vue-custom-scrollbar";
import "vue-custom-scrollbar/dist/vueScrollbar.css";
export default {
    components: {
        vueCustomScrollbar
    },
    data() {
        return {
            settings: {
                suppressScrollY: false,
                suppressScrollX: false,
                wheelPropagation: false,
            },
            caseType: 1,
            swiperList: [
                {
                    bannerList: [
                        {
                            img: ''
                        }, {
                            img: ''
                        }, {
                            img: ''
                        }, {
                            img: ''
                        }
                    ]
                }, {
                    bannerList: [
                        {
                            img: ''
                        }, {
                            img: ''
                        }, {
                            img: ''
                        }, {
                            img: ''
                        }
                    ]
                }, {
                    bannerList: [
                        {
                            img: ''
                        }, {
                            img: ''
                        }, {
                            img: ''
                        }, {
                            img: ''
                        }
                    ]
                }
            ]
        };
    },
    methods: {
        changeCaseCategory(type) {
            this.caseType = type
        },
    }
}
</script>

<style lang="scss" >
.rightBarView {
    width: 100%;
    height: 100%;
    padding: 0 0.5rem 0 0;

    .scroll-right-area {
        height: 100%;

        .captionView {
            margin-bottom: 1rem;

            .captionName {
                font-size: 1rem;
                font-family: PingFangSC, PingFang SC;
                font-weight: bolder;
                color: #333333;
            }

            .captionMore {
                font-size: 0.92rem;
                font-family: PingFangSC, PingFang SC;
                font-weight: 400;
                color: #999999;
                .iconfont{
                    font-size: 0.86rem;
                    color: #999999;
                }
            }
        }

        .articleView {
            width: 100%;
            border-radius: 0.5rem;
            background: #fff;
            margin: 0.5rem 0;
            padding: 1rem;
            box-sizing: border-box;

            .scroll-article-area {
                height: 13rem;

                .articleItem {
                    margin: 0.5rem 1rem 0.9rem 0;

                    .articleName {
                        flex: 1;
                        margin-right: 1rem;
                        font-size: 0.9rem;
                        font-family: PingFangSC, PingFang SC;
                        font-weight: 400;
                        color: #333333;
                        text-align: left;
                        overflow: hidden;
                        white-space: nowrap;
                        /* 防止文字换行 */
                        text-overflow: ellipsis;
                    }

                    .iconfont {
                        font-size: 1rem;
                        color: RGBA(216, 216, 216, 1);
                    }
                }
            }

        }

        .righBanner {
            width: 100%;
            height: 8rem;
            background: #eee;
            border-radius: 0.5rem;
        }

        .caseView {
            width: 100%;
            border-radius: 0.5rem;
            background: #fff;
            margin: 0.5rem 0;
            padding: 1rem;
            box-sizing: border-box;

            .caseCategory {
                p {
                    width: 3.5rem;
                    text-align: center;
                    font-size: 0.9rem;
                    font-family: PingFangSC, PingFang SC;
                    font-weight: 400;
                    color: #999999;
                }

                .line {
                    width: 1px;
                    height: 1rem;
                    background: #999999;
                }

            }

            .caseSwiper {
                margin-top: 1rem;
                height: 22rem;

                .bannerList {
                    width: 100%;
                    height: 100%;

                    .bannerItem {
                        width: 16.5rem;
                        height: 10rem;
                        margin: 0.4rem;
                        overflow: hidden;

                        .bannerImg {
                            width: 100%;
                            height: 100%;
                            height: 8rem;
                            background: #eee;

                        }

                        .bannerName {
                            font-size: 1rem;
                            font-family: PingFangSC, PingFang SC;
                            font-weight: 400;
                            color: #333333;
                            text-align: left;
                            padding-top: 0.5rem;
                            width: 100%;
                            overflow: hidden;
                            white-space: nowrap;
                            overflow: hidden;
                            text-overflow: ellipsis;
                        }
                    }
                }

                .el-carousel__indicators {
                    transform: translateY(-0.8rem);

                    .el-carousel__button {
                        width: 0.5rem;
                        height: 0.5rem;
                        border-radius: 50%;
                        background: #D8D8D8;
                    }

                    .is-active {
                        .el-carousel__button {
                            width: 1.8rem;
                            height: 0.4rem;
                            border-radius: 0.3rem;
                            background: #D8D8D8;
                        }
                    }
                }
            }
        }

        .productView {
            width: 100%;
            border-radius: 0.5rem;
            background: #fff;
            margin: 0.5rem 0;
            padding: 1rem;
            box-sizing: border-box;

            .productList {
                .productItem {
                    width: 100%;
                    height: 6rem;
                    background: #e5e5e5;
                    margin: 0.5rem 0;
                }
            }
        }

    }

    .ps__rail-y {
        right: 2px !important;
    }

    .ps__thumb-y {
        background: #e5e5e5 !important;
    }

    .ps__rail-y:hover {
        background: #fff !important;
    }
}
</style>