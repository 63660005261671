import http from '../http';
let productApi = {}
//获取产品列表
const getIotProductList = function (keywords, pageNo) {
    const opts = {
        url: "/iot/api/pc/product/list",
        params: {
            keywords,
            pageNo
        },
        contentType: 'application/x-www-form-urlencoded',
    }
    return new Promise((resolve, reject) => {
        http.post(opts).then(res => {
            //后期优化
            resolve(res);
        })
            .catch(err => {
                reject(err);
            })
    });
};
// 获取创建产品类目列表
const getIotProductCategoryList = function (categoryType, level, parentCategoryId) {
    const opts = {
        url: "/iot/api/pc/product/category/list",
        params: {
            categoryType,
            level,
            parentCategoryId,
        },
        contentType: 'application/x-www-form-urlencoded',
    }
    return new Promise((resolve, reject) => {
        http.post(opts).then(res => {
            //后期优化
            resolve(res);
        })
            .catch(err => {
                reject(err);
            })
    });
};
//产品编辑   1 新增 2 编辑 3 删除 5 上架 6 下架
const editIotProductInfo = function (data) {
    const opts = {
        url: "/iot/api/pc/product/edit?editType=" + data.editType,
        params: {
            accessType: data.accessType,
            categoryId: data.categoryId,
            communicationProtocol: data.communicationProtocol,
            createUserCode: data.createUserCode,
            iotModelCode: data.iotModelCode,
            iotProductCode: data.iotProductCode,
            iotProductId: data.iotProductId,
            iotProductImg: data.iotProductImg,
            iotProductName: data.iotProductName,
            iotProductStatus: data.iotProductStatus,
            isAuditUpdate: data.isAuditUpdate,
            merchantCode: data.merchantCode,
            messageProtocol: data.messageProtocol,
            samplingRate: data.samplingRate,
            sceneCode: data.sceneCode,
            schemeType: data.schemeType,
            transferProtocol: data.transferProtocol,
            userApplicationCode: data.userApplicationCode,
            iotProductDesc: data.iotProductDesc,
        },
        contentType: 'application/json',
    }
    return new Promise((resolve, reject) => {
        http.post(opts).then(res => {
            //后期优化
            resolve(res);
        })
            .catch(err => {
                reject(err);
            })
    });
};
//产品详情
const getIotProducInfo = function (id) {
    const opts = {
        url: "/iot/api/pc/product/info",
        params: {
            id,

        },
        contentType: 'application/x-www-form-urlencoded',
    }
    return new Promise((resolve, reject) => {
        http.post(opts).then(res => {
            //后期优化
            resolve(res);
        })
            .catch(err => {
                reject(err);
            })
    });
};

// 获取产品主题列表
const getIotProductTopicList = function (iotProductCode, pageNo, type) {
    const opts = {
        url: "/iot/api/pc/product/topic/list",
        params: {
            iotProductCode,
            pageNo,
            type
        },
        contentType: 'application/x-www-form-urlencoded',
    }
    return new Promise((resolve, reject) => {
        http.post(opts).then(res => {
            //后期优化
            resolve(res);
        })
            .catch(err => {
                reject(err);
            })
    });
};
// 获取产品主题详情
const getIotProductTopicInfo = function (topicCode) {
    const opts = {
        url: "/iot/api/pc/product/topic/info",
        params: {
            topicCode
        },
        contentType: 'application/x-www-form-urlencoded',
    }
    return new Promise((resolve, reject) => {
        http.post(opts).then(res => {
            //后期优化
            resolve(res);
        })
            .catch(err => {
                reject(err);
            })
    });
};
//编辑产品主题 1 新增 2 编辑 3 删除
const editIotProductTopicInfo = function (data) {
    const opts = {
        url: "/iot/api/pc/product/topic/edit?editType=" + data.editType,
        params: {
            iotProductCode: data.iotProductCode,
            iotProductTopicId: data.iotProductTopicId,
            topicCode: data.topicCode,
            topicDirection: data.topicDirection,
            topicName: data.topicName,
            topicSubPub: data.topicSubPub,
        },
        contentType: 'application/json',
    }
    return new Promise((resolve, reject) => {
        http.post(opts).then(res => {
            //后期优化
            resolve(res);
        })
            .catch(err => {
                reject(err);
            })
    });
};
//获取产品功能列表
const getIotProductFunctionList = function (iotProductCode, pageNo, functionType) {
    const opts = {
        url: "/iot/api/pc/product/function/list",
        params: {
            iotProductCode,
            pageNo,
            functionType
        },
        contentType: 'application/x-www-form-urlencoded',
    }
    return new Promise((resolve, reject) => {
        http.post(opts).then(res => {
            //后期优化
            resolve(res);
        })
            .catch(err => {
                reject(err);
            })
    });
};
//获取产品功能详情
const getIotProductFunctionInfo = function (topicCode) {
    const opts = {
        url: "/iot/api/pc/product/function/info",
        params: {
            functionCode
        },
        contentType: 'application/x-www-form-urlencoded',
    }
    return new Promise((resolve, reject) => {
        http.post(opts).then(res => {
            //后期优化
            resolve(res);
        })
            .catch(err => {
                reject(err);
            })
    });
};
//编辑产品功能 1 新增 2 编辑 3 删除
const editIotProductFunctionInfo = function (data) {
    const opts = {
        url: "/iot/api/pc/product/function/edit?editType=" + data.editType,
        params: {
            iotProductCode: data.iotProductCode,
            functionCode: data.functionCode,
            functionName: data.functionName,
            functionNameEn: data.functionNameEn,
            functionRemark: data.functionRemark,
            functionType: data.functionType,
            iotProductFunctionId: data.iotProductFunctionId,
            topicCode: data.topicCode,

        },
        contentType: 'application/json',
    }
    return new Promise((resolve, reject) => {
        http.post(opts).then(res => {
            //后期优化
            resolve(res);
        })
            .catch(err => {
                reject(err);
            })
    });
};
//获取产品功能参数列表
const getIotProductFunctionParamList = function (functionCode, type) {
    const opts = {
        url: "/iot/api/pc/product/function/param/list",
        params: {
            functionCode,
            type
        },
        contentType: 'application/x-www-form-urlencoded',
    }
    return new Promise((resolve, reject) => {
        http.post(opts).then(res => {
            //后期优化
            resolve(res);
        })
            .catch(err => {
                reject(err);
            })
    });
};
//编辑取产品功能参数
const editIotProductFunctionParam = function (editType, data) {
    const opts = {
        url: "/iot/api/pc/product/function/param/edit?editType=" + editType,
        params: data,
        contentType: 'application/json',
    }
    return new Promise((resolve, reject) => {
        http.post(opts).then(res => {
            //后期优化
            resolve(res);
        })
            .catch(err => {
                reject(err);
            })
    });
};
// 在线调试下发
const IotProductFunctionDebug = function (data) {
    const opts = {
        url: "/iot/api/pc/product/function/debug",
        params: data,
        contentType: 'application/json',
    }
    return new Promise((resolve, reject) => {
        http.post(opts).then(res => {
            //后期优化
            resolve(res);
        })
            .catch(err => {
                reject(err);
            })
    });
};
//在线调试 获取数据
const getIotProductFunctionDebugList = function (data) {
    const opts = {
        url: "/iot/api/pc/product/function/debug/list",
        params: data,
        contentType: 'application/x-www-form-urlencoded',
    }
    return new Promise((resolve, reject) => {
        http.post(opts).then(res => {
            //后期优化
            resolve(res);
        })
            .catch(err => {
                reject(err);
            })
    });
};
//获取产品注册设备列表
const getIotProductDeviceList = function (iotProductCode, keywords, pageNo) {
    const opts = {
        url: "/iot/api/pc/product/device/list",
        params: {
            iotProductCode,
            keywords,
            pageNo,
        },
        contentType: 'application/x-www-form-urlencoded',
    }
    return new Promise((resolve, reject) => {
        http.post(opts).then(res => {
            //后期优化
            resolve(res);
        })
            .catch(err => {
                reject(err);
            })
    });
};
//产品注册设备
const editIotProductDeviceInfo = function (imei, accessKey, iotProductCode) {
    const opts = {
        url: "/iot/api/pc/product/device/edit",
        params: {
            imei,
            accessKey,
            iotProductCode,

        },
        contentType: 'application/x-www-form-urlencoded',
    }
    return new Promise((resolve, reject) => {
        http.post(opts).then(res => {
            //后期优化
            resolve(res);
        })
            .catch(err => {
                reject(err);
            })
    });
};

//获取产品固件列表
const getIotProductFirmwareList = function (iotProductCode, pageNo) {
    const opts = {
        url: "/iot/api/pc/product/firmware/list",
        params: {
            iotProductCode,
            pageNo,
        },
        contentType: 'application/x-www-form-urlencoded',
    }
    return new Promise((resolve, reject) => {
        http.post(opts).then(res => {
            //后期优化
            resolve(res);
        })
            .catch(err => {
                reject(err);
            })
    });
};
//获取产品固件详情
const getIotProductFirmwareInfo = function (firmwareCode) {
    const opts = {
        url: "/iot/api/pc/product/firmware/info",
        params: {
            firmwareCode
        },
        contentType: 'application/x-www-form-urlencoded',
    }
    return new Promise((resolve, reject) => {
        http.post(opts).then(res => {
            //后期优化
            resolve(res);
        })
            .catch(err => {
                reject(err);
            })
    });
};
// 编辑产品固件 1 新增 2 编辑 3 删除
const editIotProductFirmwareInfo = function (editType, data) {
    const opts = {
        url: "/iot/api/pc/product/firmware/edit?editType=" + editType,
        params: data,
        contentType: 'application/json',
    }
    return new Promise((resolve, reject) => {
        http.post(opts).then(res => {
            //后期优化
            resolve(res);
        })
            .catch(err => {
                reject(err);
            })
    });
};
//获取产品固件版本列表
const getIotProductFirmwareVersionList = function (firmwareCode, keywords, pageNo) {
    const opts = {
        url: "/iot/api/pc/product/firmware/version/list",
        params: {
            firmwareCode,
            keywords,
            pageNo,
        },
        contentType: 'application/x-www-form-urlencoded',
    }
    return new Promise((resolve, reject) => {
        http.post(opts).then(res => {
            //后期优化
            resolve(res);
        })
            .catch(err => {
                reject(err);
            })
    });
};
//获取产品固件版本详情
const getIotProductFirmwareVersionInfo = function (versionId) {
    const opts = {
        url: "/iot/api/pc/product/firmware/version/info",
        params: {
            versionId
        },
        contentType: 'application/x-www-form-urlencoded',
    }
    return new Promise((resolve, reject) => {
        http.post(opts).then(res => {
            //后期优化
            resolve(res);
        })
            .catch(err => {
                reject(err);
            })
    });
};
//编辑产品固件版本 1 新增 2 编辑 3 删除
const editIotProductFirmwareVersionInfo = function (editType, data) {
    const opts = {
        url: "/iot/api/pc/product/firmware/version/edit?editType=" + editType,
        params: data,
        contentType: 'application/json',
    }
    return new Promise((resolve, reject) => {
        http.post(opts).then(res => {
            //后期优化
            resolve(res);
        })
            .catch(err => {
                reject(err);
            })
    });
};
//升级固件
const sendIotProductFirmware = function (versionId, firmwareCode) {
    const opts = {
        url: "/iot/api/pc/product/firmware/send",
        params: {
            versionId,
            firmwareCode
        },
        contentType: 'application/x-www-form-urlencoded',
    }
    return new Promise((resolve, reject) => {
        http.post(opts).then(res => {
            //后期优化
            resolve(res);
        })
            .catch(err => {
                reject(err);
            })
    });
};

export default productApi = {
    getIotProductList,
    getIotProductCategoryList,
    editIotProductInfo,
    getIotProducInfo,
    getIotProductTopicList,
    getIotProductTopicInfo,
    editIotProductTopicInfo,
    getIotProductFunctionList,
    getIotProductFunctionInfo,
    editIotProductFunctionInfo,
    getIotProductFunctionParamList,
    editIotProductFunctionParam,
    IotProductFunctionDebug,
    getIotProductFunctionDebugList,
    getIotProductDeviceList,
    editIotProductDeviceInfo,
    getIotProductFirmwareList,
    getIotProductFirmwareInfo,
    editIotProductFirmwareInfo,
    getIotProductFirmwareVersionList,
    getIotProductFirmwareVersionInfo,
    editIotProductFirmwareVersionInfo,
    sendIotProductFirmware,
}