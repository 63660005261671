<template>
  <div class="headBar">
    <div class="headBarView flex_g vertical between">
      <div class="headLogo">
        <!-- <img src="@/assets/images/headBar/headLogo.png" alt=""> -->
      </div>
      <div class="headEditView flex_g vertical">
        <!-- <p>帮助</p>
                <p>文档</p>
                <p>技术支持</p>
                <p>我的空间</p> -->
        <!-- <i class="iconfont icon icon-gouwucheman"></i> -->
        <!-- <div class="headEditView flex_g vertical" @click="openUesrMenu">
          <i class="iconfont icon icon-geren"></i>
          <p>我的账户</p>
        </div> -->
        <div class="userMenuBar" v-if="userMenuBar">
          <div class="laywer" @click="closeModal"></div>
          <div class="userMenuView">
            <!-- <div class="userInfoView flex_g vertical">
              <div class="userImg">
                <img v-if="avatarUrl" :src="avatarUrl" alt="">
                <img v-if="!avatarUrl" src="https://haya-cloud.oss-cn-shanghai.aliyuncs.com/hayaCloud3/17140266241152dad9cb17fc300ee072f4a899f13b18.png" alt="">
              </div>
              <div style="flex:1;overflow: hidden;">
                <div class="nameView flex_g vertical">
                  <div class="userName">{{ nickName }}</div>
                </div>
                <div class="userPhone">{{ userPhone }}</div>
              </div>
            </div> -->
            <div class="loginout" @click="loginOut">
              退出登录
            </div>
          </div>

        </div>
      </div>
    </div>

  </div>
</template>

<script>
export default {
  data() {
    return {
      userMenuBar: false,
      avatarUrl: "",
      nickName: "",
      userPhone: "",
    };
  },
  mounted() {
    // this.getUserInfo();
  },
  methods: {
    getUserInfo() {
      this.$api.accountApi.getUserInfo().then((res) => {
        if (res.resultCode == 10000) {
          if (res.result.userBase) {
            let info = res.result.userBase;
            this.nickName = info.userNick;
            this.avatarUrl = info.userImg;
            this.userPhone =
              info.telephone.substring(0, 3) +
              "****" +
              info.telephone.substring(7, 12);
            this.userBaseInfo = info;
          }
        }
      });
    },
    openUesrMenu() {
      this.userMenuBar = true;
    },
    closeModal() {
      this.userMenuBar = false;
    },
    loginOut() {
      this.$api.loginApi.logout(1).then((res) => {
        if (res.resultCode == 10000) {
          document.cookie = "";
          this.userMenuBar = false;
          this.$router.replace({
            path: "/login",
          });
        }
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.headBar {
  width: 100%;
  height: 60px;
  background: #fff;
  border-bottom: 1px solid rgba(209, 209, 209, 0.5);
  position: relative;
  .headBarView {
    width: 100%;
    height: 100%;
    .logo {
      padding: 0 2rem;
      img {
        width: 6rem;
        display: block;
        margin: 0 auto;
      }
    }

    .messageWrapper {
      flex: 1;
      height: 3rem;
      padding: 0 2rem;
      .el-carousel {
        flex: 1;
        height: 100%;
      }

      .messageItem {
        height: 100%;
        img {
          width: 1rem;
        }

        .messageContent {
          font-size: 1rem;
          font-weight: 400;
          color: #576275;
          margin: 0 0.5rem;
        }

        .messageBtn {
          font-size: 1rem;
          font-weight: 400;
          color: #336ff1;
        }
      }
    }

    .companyView {
      flex: 1;
      height: 100%;
      padding: 0 0.5rem;
      border-left: 1px solid #eee;

      img {
        width: 2rem;
        height: 2rem;
      }

      .companyName {
        font-size: 1rem;
        font-weight: 400;
        margin: 0 0.5rem;
      }

      .userStatusView {
        margin-right: 0.5rem;

        .userStatus {
          width: 3.5rem;
          height: 1.5rem;
          background: rgba(192, 64, 63, 0.1);
          border-radius: 0.2rem;
          font-size: 1rem;
          font-weight: 400;
          text-align: center;
          color: rgba(192, 64, 63, 1);
          line-height: 1.5rem;
        }

        .rightStatus {
          background: rgba(109, 212, 0, 0.1);
          color: rgba(89, 149, 26, 1);
        }

        .waitStatus {
          background: rgba(225, 95, 24, 0.11);
          color: rrgba(225, 95, 24, 1);
        }

        .aduitStatus {
          background: rgba(255, 244, 244, 1);
          color: rgba(192, 64, 63, 1);
        }
      }

      .icon-sanjiaoxing {
        font-size: 1rem;
        color: #d8d8d8;
        transform: rotate(90deg);
      }
    }

    .headEditView {
      margin-right: 1rem;

      p {
        margin: 0 0.5rem;
        font-size: 1rem;
        font-family: PingFangSC, PingFang SC;
        font-weight: 500;
        color: #576275;
        cursor: default;
      }

      .icon-geren {
        font-size: 1.3rem;
        color: #576275;
      }
    }
  }
  .userMenuBar {
    .laywer {
      position: fixed;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      background: rgba(255, 255, 255, 0);
      z-index: 9999;
    }

    .userMenuView {
      position: fixed;
      top: 70px;
      right: 1rem;
      background: #fff;
      box-shadow: 0rem 0.14rem 0.49rem 0rem rgba(194, 194, 194, 0.8);
      border-radius: 0.36rem;
      width: 20rem;
      z-index: 10000;
      overflow: hidden;

      .userInfoView {
        width: 100%;
        padding: 2rem 1.7rem;
        background: linear-gradient(
          95deg,
          rgba(175, 201, 255, 0.2) 0%,
          rgba(240, 246, 255, 0.2) 100%
        );
        box-sizing: border-box;
        overflow: hidden;

        .userImg {
          width: 3.5rem;
          height: 3.5rem;
          border-radius: 0.52rem;

          margin-right: 0.7rem;
          flex-shrink: 0;

          img {
            width: 100%;
            height: 100%;
            border-radius: 0.52rem;
          }
        }

        .nameView {
          width: 100%;
          overflow: hidden;

          .userName {
            flex: 1;
            font-size: 1.21rem;
            font-weight: 600;
            color: #333333;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
          }

          img {
            flex-shrink: 0;
            width: 4.86rem;
            margin-left: 0.3rem;
          }
        }

        .userPhone {
          margin-top: 0.5rem;
          font-size: 1rem;
          font-weight: 400;
          color: #666666;
          text-align: left;
        }
      }

      .menuList {
        padding: 1rem 0;

        .menuItem {
          padding: 0.8rem 1.5rem;
          img {
            width: 1.2rem;
            margin-right: 0.52rem;
          }

          .menuName {
            flex: 1;
            text-align: left;
            font-size: 1rem;
            font-weight: 500;
            color: #576275;
          }

          .icon-jiantouyou {
            color: #d8d8d8;
            font-size: 1rem;
          }
        }
      }

      .loginout {
        border-top: 1px solid #ddd;
        font-size: 1rem;
        font-weight: 600;
        color: #336ff1;
        padding: 1rem 2rem;
      }
    }
  }
}
</style>