<template>
  <div id="app" class="flex_g  ">
    <div class="menuBar" v-if="isShow" >
        <div class="headLogo" >
          <img src="@/assets/images/headLogo_2.png" alt="">
        </div>
        <div class="menuListView flex_g flex_center" >
          <div class="menuList">
            <div class="menuItem " v-for="(item, index) of menuList" :key="index">
              <div class="fisrtMenu flex_g vertical between" :class="firstMenu == item.menuUrl ?'menuSelect':''" @click="goPage(item)">
                <img :style="firstMenu == item.menuUrl ? 'opacity:1' : ''" :src="item.menuIcon" alt="">
                <p :style="firstMenu == item.menuUrl ? 'color: #fff;' : ''">{{ item.menuName }}</p>
                <i class="iconfont icon-jiantouyou" v-if="item.children.length>0" :style="item.isChildrenShow ? 'transform: rotate(90deg) scale(0.7) translate(0.3rem, -0.3rem) ; ' : ''"></i>
                <div v-if="firstMenu == item.menuUrl" class="pageMenuLine"></div>
              </div>
              <div class="secondMenu" v-if="item.children.length>0&&item.isChildrenShow">
                <div class="secondMenuList">
                  <div class="secondMenuItem" @click="goSecondPage(item, Sitem)" v-for="(Sitem,Sindex) of item.children" :class="secondMenu==Sitem.menuUrl?'pageSecMenu':''" :key="Sindex">
                    {{ Sitem.menuName }}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="version">
          <p>哈雅云 v3.1</p>
        </div>
      </div>

    <div class="appPageContainer flex_g flex_column">
      <div class="topHead" v-if="isShow">
        <headBar></headBar>
      </div>
      <div class="flex_g " style="flex: 1;overflow: hidden;">
        <div class="pageContainer" :style="isShow ? '' : 'padding:0'">
          <router-view />
        </div>
        <!-- <div class="rightBar" v-if="isRightBar">
          <rightBar></rightBar>
        </div>
        <div class="helpModal flex_g flex_column" ref="helpModal" draggable="true" @dragstart="dragMoveStart"
          @dragend="dragMoveEnd" :class="isRight ? 'helpRightModal' : ''"
          :style="'left:' + helpMoveX + 'px;top:' + helpMoveY + 'px'" v-if="helpModal">
          <div class="modalHead flex_g vertical between">
            <div class="modalHeadLeft flex_g vertical">
              <i class="iconfont icon-zuojiantou"></i>
              <p>帮助和服务</p>
            </div>
            <div class="modalHeadRight flex_g vertical ">
              <i class="iconfont icon-icon-test" @click="changePosition"></i>
              <i class="iconfont icon-guanbi" @click="closeHelpModal"></i>
            </div>
          </div>
          <div class="modalContainer">
            帮助帮助帮助
          </div>
        </div>
        <div class="rightHelpBar" @click="openHelpModal" v-if="isShow">
          <div class="rightHelpBarView flex_g vertical">
            <i class="iconfont icon-yk_zhinan_fill"></i>
            <p>平台使用指南</p>
          </div>
        </div> -->
      </div>


    </div>


  </div>
</template>

<script>
import headBar from './components/mainBar/headBar.vue'
import rightBar from './components/mainBar/rightBar.vue'

export default {
  name: "app",
  provide() {
    return {

      reload: this.reload
    }
  },

  components: {
    headBar,
    rightBar,

  },
  data() {
    return {
      isRight: false,
      isAlive: true,
      isRouterAlive: false,
      isShow: false,
      firstMenu: 'client',
      menuList: [
        // {
        //   menuIcon: '',
        //   menuName: '首页',
        //   menuUrl: 'index',
        //   children: []
        // },
        {
          menuIcon: require('@/assets/images/menu/application.png'),
          menuName: '应用管理',
          menuUrl: 'application',
          children: []
        },
        {
          menuIcon: require('@/assets/images/menu/books.png'),
          menuName: '知识库管理',
          menuUrl: 'books',
          children: []
        },

      ],
      secondMenuList: [],
      secondMenu:'',
      helpModal: false,
      helpMoveStartX: 0,
      helpMoveStartY: 0,

      helpMoveX: 0,
      helpMoveY: 0,
      isRightBar: false,//首页右侧栏



    };
  },
  watch: {
    $route(route) {
      if (
        route.fullPath != "/login" &&
        route.fullPath != "/register" &&
        route.fullPath != '/forgetPassword' &&
        route.fullPath != '/error'
      ) {
        if (route.fullPath == "/index") {
          this.isRightBar = true
        } else {
          this.isRightBar = false
        }
        console.log(route);
        let fullPath = route.fullPath.split("/")
        console.log(fullPath)

        this.firstMenu = fullPath[1];
        this.secondMenu = fullPath[2];
        this.isShow = true;
        this.helpModal = false;
        this.isRight = true;
      } else {
        this.isShow = false;
        this.helpModal = false;
        this.isRightBar = false;
        this.isRight = false;
      }

    },
  },
  mounted() {

  },
  methods: {
    reload() {
      this.isRouterAlive = false
      this.$nextTick(function () {
        this.isRouterAlive = true
      })
    },
    getSecondMenuList(item) {
      if (item.children && item.children.length > 0) {
        this.secondMenuList = item.children
      } else {
        this.secondMenuList = []
      }
    },
    clearSecondMenu() {
      this.secondMenuList = []
    },
    initHelpModal() {
      let eleWidth = this.$refs.helpModal.clientWidth
      let eleHeight = this.$refs.helpModal.clientHeight

      let clientWidth = window.innerWidth
      let clientHeight = window.innerHeight
      this.maxMoveX = clientWidth - eleWidth
      this.maxMoveY = clientHeight - eleHeight
      this.helpMoveX = clientWidth - eleWidth - 150
      this.helpMoveY = 60

    },
    changePosition() {
      this.isRight = !this.isRight
      if (!this.isRight) {
        this.initHelpModal()
      }
    },
    dragMoveStart(e) {
      if (this.isRight) {
        return false
      }
      this.helpMoveStartX = e.clientX
      this.helpMoveStartY = e.clientY
    },
    dragMoveEnd(e) {
      if (this.isRight) {
        return false
      }
      let clientX = e.clientX - this.helpMoveStartX
      let clientY = e.clientY - this.helpMoveStartY
      this.helpMoveX += clientX
      if (this.helpMoveX < 0) {
        this.helpMoveX = 0
      }
      if (this.helpMoveX > this.maxMoveX) {
        this.helpMoveX = this.maxMoveX
      }
      this.helpMoveY += clientY
      if (this.helpMoveY < 0) {
        this.helpMoveY = 0
      }
      if (this.helpMoveY > this.maxMoveY) {
        this.helpMoveY = this.maxMoveY
      }
    },
    closeHelpModal() {
      this.helpModal = false
      this.isRight = false
    },
    openHelpModal() {
      this.helpModal = true
      this.isRight = false
    },
    goPage(item) {
      if (item.children.length > 0) {
        return false
      }
      this.$router.push({
        path: '/' + item.menuUrl
      })
    },
    goSecondPage(item, sItem) {
      console.log(item)
      this.$router.push({
        path: '/' + item.menuUrl + '/' + sItem.menuUrl
      })
    }
  }

};
</script>

<style lang="scss">
#app {
  font-family: "Microsoft YaHei", "NotoSans CJK", "PingFang-SC-Medium";
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  height: 100vh;
  overflow: hidden;
  background: rgba(247, 247, 247, 1);

  .menuBar {
      width: 14rem;
      height: 100%;
      flex-shrink: 0;
      background: linear-gradient(360deg, #69abf9 0%, #336ff1 100%);
      position: relative;
      .headLogo {
        padding: 2rem 0 1rem;

        img {
          width: 6rem;
          display: block;
          margin: 0 auto;
        }
      }
      .menuListView {
        width: 100%;
        height: 100%;

        .menuList {
          width: 100%;
          padding: 1rem 0;
          position: relative;
          box-sizing: border-box;
          z-index: 9;

          .menuItem {
            cursor: default;
            position: relative;
            .fisrtMenu {
              padding: 1rem 2rem;
              img {
                width: 1.1rem;
                margin-right: 1rem;
                opacity: 1;
              }

              p {
                flex: 1;
                text-align: left;
                font-size: 1rem;
                font-weight: bold;
                color: #fff;
              }
              .iconfont {
                font-size: 1rem;
                font-weight: 400;
                color: #fff;
                transition: all 0.3s;
                transform: scale(0.7) translate(0.6rem, 0);
              }

              .pageMenuLine {
                position: absolute;
                left: 0;
                top: 0;
                bottom: 0;
                width: 0.14rem;

                background: linear-gradient(180deg, #058dfb 0%, #1758db 100%);
              }
            }
            .fisrtMenu:hover {
              background: #6799ff;
            }
            .menuSelect {
              background: #5288fe;
              position: relative;
              .pageMenuLine {
                position: absolute;
                left: 0;
                top: 0;
                bottom: 0;
                width: 2px;
                background: linear-gradient(180deg, #058dfb 0%, #1758db 100%);
              }
            }
            .secondMenu {
              .secondMenuList {
                .secondMenuItem {
                  color: #fff;
                  font-style: 1rem;
                  padding: 0.9rem 0 0.9rem 4.1rem;
                  opacity: 0.7;
                  text-align: left;
                }
                .secondMenuItem:hover {
                  background: #6799ff;
                  opacity: 1;
                }
                .pageSecMenu {
                  // background: #5288fe;
                  position: relative;
                  opacity: 1;
                  .pageMenuLine {
                    position: absolute;
                    left: 0;
                    top: 0;
                    bottom: 0;
                    width: 2px;
                    background: linear-gradient(
                      180deg,
                      #058dfb 0%,
                      #1758db 100%
                    );
                  }
                }
              }
            }
          }

          .menuItem:hover {
            img {
              opacity: 1;
            }

            p {
              color: #fff;
            }
          }
        }
      }
      .secondMenuBar {
        position: absolute;
        right: -11rem;
        // top: 60px;
        top: 0;
        bottom: 0;
        width: 11rem;
        height: 100%;
        background: #f8f8f8;
        // box-shadow: 4px 0px 9px 0px rgba(209, 209, 209, 0.5);
        z-index: 10000;

        .secondMenuBarList {
          padding: 1rem 0;

          .secondMenuBarItem {
            text-align: left;
            // padding: 1rem 0;

            .secondMenuName {
              font-size: 1rem;
              font-family: PingFangSC, PingFang SC;
              font-weight: bold;
              color: #000000;
              padding: 0 2rem 0rem;
            }

            .thirdMenuList {
              padding: 1rem 0 0;

              .thirdMenuItem {
                font-size: 1rem;
                font-family: PingFangSC, PingFang SC;
                font-weight: 400;
                color: #373d4a;
                padding: 1rem 2rem;
                cursor: default;
                .iconfont {
                  color: #fff;
                  font-size: 1rem;
                  transform: scale(0.8);
                }
              }

              .thirdMenuItem:hover {
                color: #336ff1;
              }
              .pageSecMenu {
                background: #336ff1;
                color: #fff !important;
              }
            }
          }
        }
      }
      .version {
        position: absolute;
        left: 50%;
        bottom: 1.5rem;
        transform: translate(-50%, 0);
        background: #4f9afa;
        border-radius: 15px;
        padding: 0.5rem 0rem;
        width: 7rem;
        text-align: center;
        p {
          color: #fff;
          font-weight: bold;
          font-size: 1rem;
        }
      }
    }

  .topHead {
    flex-shrink: 0;
  }

  .appPageContainer {
    flex: 1;
    height: 100%;
    overflow: hidden;
    transition: all 0.5s;

    .pageContainer {
      flex: 1;
      height: 100%;
      box-sizing: border-box;
      overflow-y:auto ;
    }

    .rightBar {
      width: 18rem;
      flex-shrink: 0;
      height: 100%;
      overflow: hidden;
    }
  }

  .el-main {

    input::-webkit-input-placeholder {
      color: #999;
    }

    input::-moz-input-placeholder {
      color: #999;
    }

    input::-ms-input-placeholder {
      color: #999;
    }

    textarea::-webkit-input-placeholder {
      color: #999;
    }

    textarea::-moz-input-placeholder {
      color: #999;
    }

    textarea::-ms-input-placeholder {
      color: #999;
    }
  }

  .rightHelpBar {
    position: fixed;
    right: 0;
    bottom: 10rem;
    border-radius: 30px 0 0 30px;
    background: #0954FF;
    padding: 1rem;
    z-index: 99999;
    width: 3.5rem;
    overflow: hidden;
    transition: all 0.5s;
    cursor: default;

    .rightHelpBarView {
      .iconfont {
        font-size: 2rem;
        color: #fff;
        margin-right: 1rem;
        flex-shrink: 0;
      }

      p {
        flex-shrink: 0;
        font-size: 1rem;
        font-family: PingFangSC, PingFang SC;
        font-weight: 500;
        color: #FFFFFF;
      }
    }
  }

  .rightHelpBar:hover {
    width: 11rem;
  }

  .helpModal {
    position: fixed;
    height: 85%;

    bottom: 5rem;
    width: 30rem;
    z-index: 9999;
    transition: all 0.5s;

    .modalHead {
      padding: 1rem;
      background: #0A55FF;
      border-radius: 4px;
      cursor: move;

      .modalHeadLeft {
        .icon-zuojiantou {
          font-size: 1.1rem;
          color: #fff;
          margin-right: 0.5rem;
          cursor: pointer;
        }

        p {
          font-size: 1rem;
          font-family: PingFangSC, PingFang SC;
          font-weight: 500;
          color: #FFFFFF;
        }
      }

      .modalHeadRight {
        .icon-icon-test {
          font-size: 1.2rem;
          color: #fff;
          margin-right: 0.5rem;
          transform: rotate(-45deg);
          cursor: pointer;
        }

        .icon-guanbi {
          font-size: 1.1rem;
          color: #fff;
          cursor: pointer;
        }
      }
    }

    .modalContainer {
      width: 100%;
      flex: 1;
      background: #fff;
      box-shadow: 0px 3px 13px 4px rgba(174, 174, 174, 0.3);
      padding: 1rem;
    }
  }

  .helpRightModal {
    top: 4rem !important;
    bottom: 0;
    left: 100% !important;
    width: 25rem;
    margin-left: -25rem;
    height: auto;

    .modalHead {
      cursor: default;

      .modalHeadRight {
        .icon-icon-test {}
      }
    }
  }
}
</style>