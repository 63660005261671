<template>
    <div class="confirmTip" v-show="status">
        <div class="confirmView">
            <p class="title">
                <span>提示</span>
                <img src="@/assets/images/close_gray.png" @click="close" />
            </p>
            <p class="content">{{content}}</p>
            <div class="confirmBtn flex_g vertical flex_center">
                <p @click="close">取消</p>
                <p @click="config">确定</p>
            </div>
        </div>

    </div>
</template>

<script>
export default {
    name: "messageDialog",
    data() {
        return {
            status: false, //是否展示
            content: "确认要删除么？",
            contentId: '',
        };
    },
    methods: {
        open(content, id) {
            this.status = true;
            if (content != null && content != undefined) {
                this.content = content;
            }
            if (id != null && id != undefined) {
                this.contentId = id;
            }
        },
        close() {
            this.status = false;
            this.$emit("cancle");
        },
        config() {
            this.status = false;
            this.$emit("config", this.contentId);
        }
    }
};
</script>

<style lang="scss" scoped>
.confirmTip {
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.7);
    position: fixed;
    top: 0;
    left: 0;
    z-index: 1000000;
    .confirmView {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        background: #fff;
        width: 380px;
        border-radius: 10px;
        .title {
            padding: 20px 0;
            position: relative;
            border-bottom: 1px solid #e5e5e5;
            text-align: center;
            span {
                color: #333;
                font-size: 1.1rem;
                line-height: 1;
            }
            img {
                width: 13px;
                height: 14px;
                position: absolute;
                top: 12px;
                right: 12px;
            }
        }
        .content {
            color: #333;
            font-size: 1.1rem;
            line-height: 76px;
            height: 76px;
            text-align: center;
        }

        .confirmBtn {
            margin-bottom: 1.5rem;
            p {
                width: 6rem;
                padding: 0.5rem 0;
                border-radius: 5px;
                font-size: 1rem;
                margin: 0 1rem;
                text-align: center;
                cursor: pointer;
            }
            p:nth-child(1) {
                color: #999;
                border: 1px solid #e5e5e5;
            }
            p:nth-child(2) {
                color: #fff;
                background: #336ff1;
            }
        }
    }
}
</style>