function getDate(date,splitIcon,type){
    var date = new Date(date);
    if(type=='day'){
        return date.getFullYear() + splitIcon + (date.getMonth()+1 < 10 ? '0'+(date.getMonth()+1) : date.getMonth()+1) + splitIcon + (date.getDate() < 10 ? '0' + date.getDate() : date.getDate())
    }else if(type=='hour'){
        return (date.getHours() < 10 ? '0'+(date.getHours()) : date.getHours()) + ':' + (date.getMinutes() < 10 ? '0'+(date.getMinutes()) : date.getMinutes())+ ':' + (date.getSeconds() < 10 ? '0'+(date.getSeconds()) : date.getSeconds())
    } else if(type=='second') {
        return date.getFullYear() + splitIcon + (date.getMonth()+1 < 10 ? '0'+(date.getMonth()+1) : date.getMonth()+1) + splitIcon + (date.getDate() < 10 ? '0' + date.getDate() : date.getDate()) + ' ' + (date.getHours() < 10 ? '0'+(date.getHours()) : date.getHours()) + ':' + (date.getMinutes() < 10 ? '0'+(date.getMinutes()) : date.getMinutes())+ ':' + (date.getSeconds() < 10 ? '0'+(date.getSeconds()) : date.getSeconds())

    }else {
        return date.getFullYear() + splitIcon + (date.getMonth()+1 < 10 ? '0'+(date.getMonth()+1) : date.getMonth()+1) + splitIcon + (date.getDate() < 10 ? '0' + date.getDate() : date.getDate()) + ' ' + (date.getHours() < 10 ? '0'+(date.getHours()) : date.getHours()) + ':' + (date.getMinutes() < 10 ? '0'+(date.getMinutes()) : date.getMinutes())

    }
}
export default getDate;