<template>
    <div class="blackTip" v-show="status">
      
            <p class="content">{{ content }}</p>

    </div>
</template>

<script>
export default {
    name: "messageDialog",
    data() {
        return {
            status:false,
            content: '',

        };
    },
    methods: {
        open(content, id) {
            this.status = true;
            if (content != null && content != undefined) {
                this.content = content;
            }
            let that=this
            setTimeout(function () {
                that.status = false
            }, 2000)
        },

    }
};
</script>

<style lang="scss" scoped>
.blackTip {
  
    background: rgba(0, 0, 0, 0.7);
    position: absolute;
    top: 50%;
    left: 50%;
    max-width: 20rem;
    transform: translate(-50%,-50%);
    border-radius: 0.5rem;
    z-index: 1000000;
    padding: 1rem 2rem;

        .content {
            color: #fff;
            font-size: 1.1rem;
            line-height: 1.5rem;
            text-align: center;
        }

        
    
}</style>