const config = {


//  platformUrl: 'http://192.168.124.6:8577',
   platformUrl: '/p',
  // platformUrl: 'https://platform.server.hzhaya.com',
  // merchantUrl: 'https://merchant.server.hzhaya.com',
   merchantUrl: '/m', 
  // loginUrl: '/a',
  //  minIotUrl: '/i',

};
export default config;