import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import './assets/base.css'
import './assets/form.scss'
import './assets/table.scss'
import './assets/rightModal.scss'
import './assets/fonts/iconfonts/iconfont.css'
import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
import { post, get, upload } from './http/http';
import api from './http/api';//总api
import getDate from './utils/getDate';
import blackTip from './components/blackTip';
import errorTip from './components/errorTip';
import confirmTip from './components/confirmTip';
import pagination from './components/pagination';
import mavonEditor from 'mavon-editor'
import 'mavon-editor/dist/css/index.css'
// use
Vue.use(mavonEditor)

Vue.config.productionTip = false
Vue.use(ElementUI);
Vue.use(api); //api 函数
Vue.use(confirmTip) //全局确认弹窗提示

Vue.use(errorTip); //
Vue.use(blackTip); //
Vue.use(pagination); //

Vue.prototype.$get = get;
Vue.prototype.$post = post;
Vue.prototype.$upload = upload;
Vue.prototype.$getDate = getDate;

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
