import http from '../http';
let addServiceApi = {}
//产品中心
const getPlatProductList = function (keywords, pageNo,categoryId) {
    const opts = {
        url: "/app/pc/api/plat/product/list",
        params: {
            keywords,
            pageNo,
            categoryId,
        },
        contentType: 'application/x-www-form-urlencoded',
        apiUrl: 'merchant'
    }
    return new Promise((resolve, reject) => {
        http.post(opts).then(res => {
            resolve(res);
        })
            .catch(err => {
                reject(err);
            })
    });
};
//产品详情
const getPlatProductInfo = function (platProductCode) {
    const opts = {
        url: "/app/pc/api/platProduct/info",
        params: {
            platProductCode
        },
        contentType: 'application/x-www-form-urlencoded',
        apiUrl: 'merchant'
    }
    return new Promise((resolve, reject) => {
        http.post(opts).then(res => {
            resolve(res);
        })
            .catch(err => {
                reject(err);
            })
    });
};

// 产品-预下单
const platProductOrderPre = function (platProductCode, skuId,buyNum,couponCode) {
    const opts = {
        url: "/app/pc/api/user/platProdcut/order/pre",
        params: {
            platProductCode,
            skuId,
            buyNum,
            couponCode
        },
        contentType: 'application/x-www-form-urlencoded',
        apiUrl: 'merchant'
    }
    return new Promise((resolve, reject) => {
        http.post(opts).then(res => {
            resolve(res);
        })
            .catch(err => {
                reject(err);
            })
    });
};
// 产品-下单
const platProductToOrder = function (platProductCode, skuId,buyNum,couponCode) {
    const opts = {
        url: "/app/pc/api/user/platProduct/order",
        params: {
            platProductCode,
            skuId,
            buyNum,
            couponCode
        },
        contentType: 'application/x-www-form-urlencoded',
        apiUrl: 'merchant'
    }
    return new Promise((resolve, reject) => {
        http.post(opts).then(res => {
            resolve(res);
        })
            .catch(err => {
                reject(err);
            })
    });
};
// 产品-支付
const orderPay = function (orderSn, type) {
    const opts = {
        url: "/app/api/wx/pay",
        params: {
            orderSn,
            type
        },
        contentType: 'application/x-www-form-urlencoded',
        apiUrl: 'merchant'
    }
    return new Promise((resolve, reject) => {
        http.post(opts).then(res => {
            resolve(res);
        })
            .catch(err => {
                reject(err);
            })
    });
};
export default addServiceApi = {
    getPlatProductList,
    getPlatProductInfo,
    platProductOrderPre,
    platProductToOrder,
    orderPay
}