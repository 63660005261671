import http from '../http';
let applicationApi = {}
// 应用-获取用户规则列表
const getRuleList = function (pageNo, keywords, iotAppCode) {
    const opts = {
        url: "/iot/api/pc/rule/list",
        params: {
            pageNo,
            keywords,
            iotAppCode,
        },
        contentType: 'application/x-www-form-urlencoded',
    }
    return new Promise((resolve, reject) => {
        http.post(opts).then(res => {
            resolve(res);
        })
            .catch(err => {
                reject(err);
            })
    });
};
//应用-用户规则编辑  editType 1 新增 2 编辑 3 删除 5 生效 6 失效|ruleType 1 自定义 2 规则模版
const editRuleInfo = function (data) {
    const opts = {
        url: "/iot/api/pc/rule/edit",
        params: data,
        contentType: 'application/x-www-form-urlencoded',
    }
    return new Promise((resolve, reject) => {
        http.post(opts).then(res => {
            resolve(res);
        })
            .catch(err => {
                reject(err);
            })
    });
};

//应用-获取用户应用列表
const getUserAppList = function (pageNo, keywords) {
    const opts = {
        url: "/iot/api/pc/app/list",
        params: {
            pageNo,
            keywords
        },
        contentType: 'application/x-www-form-urlencoded',
    }
    return new Promise((resolve, reject) => {
        http.post(opts).then(res => {
            resolve(res);
        })
            .catch(err => {
                reject(err);
            })
    });
};
//应用-用户编辑应用  editType 1 新增 2 编辑 3 删除 5 开启 6 关闭
const editUserAppInfo = function (data) {
    const opts = {
        url: "/iot/api/pc/app/edit",
        params: data,
        contentType: 'application/x-www-form-urlencoded',
    }
    return new Promise((resolve, reject) => {
        http.post(opts).then(res => {
            resolve(res);
        })
            .catch(err => {
                reject(err);
            })
    });
};
//应用-获取用户应用详情
const getUserAppInfo = function (iotAppCode) {
    const opts = {
        url: "/iot/api/pc/app/info",
        params: {
            iotAppCode
        },
        contentType: 'application/x-www-form-urlencoded',
    }
    return new Promise((resolve, reject) => {
        http.post(opts).then(res => {
            resolve(res);
        })
            .catch(err => {
                reject(err);
            })
    });
};
//应用-获取用户应用功能列表
const getUserAppFunctionList = function (pageNo, iotAppCode) {
    const opts = {
        url: "/iot/api/pc/app/function/list",
        params: {
            pageNo,
            iotAppCode
        },
        contentType: 'application/x-www-form-urlencoded',
    }
    return new Promise((resolve, reject) => {
        http.post(opts).then(res => {
            resolve(res);
        })
            .catch(err => {
                reject(err);
            })
    });
};
//应用-编辑用户应用功能 1 新增 2 编辑 3 删除 4 复制 5 上架 6 下架|funtionType 1 get 2 post
const editUserAppFunctionInfo = function (data) {
    const opts = {
        url: "/iot/api/pc/app/function/edit",
        params: data,
        contentType: 'application/x-www-form-urlencoded',
    }
    return new Promise((resolve, reject) => {
        http.post(opts).then(res => {
            resolve(res);
        })
            .catch(err => {
                reject(err);
            })
    });
};
//应用-获取用户应用功能数据
const getUserAppFunctionDataList = function (data) {
    const opts = {
        url: "/iot/api/pc/app/funcation/data/list",
        params: data,
        contentType: 'application/x-www-form-urlencoded',
    }
    return new Promise((resolve, reject) => {
        http.post(opts).then(res => {
            resolve(res);
        })
            .catch(err => {
                reject(err);
            })
    });
};
//应用-获取用户应用功能编辑
const editUserAppFunctionData = function (data) {
    const opts = {
        url: "/iot/api/pc/app/funcation/data/edit",
        params: data,
        contentType: 'application/x-www-form-urlencoded',
    }
    return new Promise((resolve, reject) => {
        http.post(opts).then(res => {
            resolve(res);
        })
            .catch(err => {
                reject(err);
            })
    });
};
export default applicationApi = {
    getRuleList,
    editRuleInfo,
    getUserAppList,
    editUserAppInfo,
    getUserAppInfo,
    getUserAppFunctionList,
    editUserAppFunctionInfo,
    getUserAppFunctionDataList,
    editUserAppFunctionData
}