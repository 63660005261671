import http from '../http';
import md5 from "js-md5";
let loginApi = {}
//登录
const Login = function ( userName, password) {
    const opts = {
        url: "/plat/api/auth/login",
        params: {
            userName,
            password: md5(password)
        },
        apiUrl: 'common',
        contentType: 'application/x-www-form-urlencoded'
    }
    return new Promise((resolve, reject) => {
        http.post(opts).then(res => {
            resolve(res);
        })
            .catch(err => {
                reject(err);
            })
    });
};

//退出登录
const logout = function () {
    const opts = {
        url: "/plat/api/auth/logout",
        params: {},
        contentType: 'application/x-www-form-urlencoded',
        apiUrl: 'common'
    }
    return new Promise((resolve, reject) => {
        http.post(opts).then(res => {
            resolve(res);
        })
            .catch(err => {
                reject(err);
            })
    });
};
export default loginApi = {
    Login,
    logout
}