import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

const originalPush = VueRouter.prototype.push
VueRouter.prototype.push = function push(location) {
  return originalPush.call(this, location).catch(err => err)
}
const originalReplace = VueRouter.prototype.replace
VueRouter.prototype.replace = function replace(location) {
  return originalReplace.call(this, location).catch(err => err)
}

const routes = [
  {
    path: '*',
    redirect: '/'
  },
  {
    path: '/',
    redirect: '/login'
  },
  {
    path: '/error',
    name: 'error',
    component: () =>
      import('../pages/error')
  },
  {
    path: '/login',
    name: 'login',
    component: () =>
      import('../pages/loginModule/login')
  },
  {
    path: '/document/:documentId',
    name: 'document',
    component: () =>
      import('../pages/document/document')
  },
  // {
  //   path: '/index',
  //   name: 'index',
  //   component: () =>
  //     import('../pages/index')
  // },
  // {
  //   path: '/client/normalClient',
  //   name: 'normalClient',
  //   component: () =>
  //     import('../pages/client/normalClient')
  // },
  // {
  //   path: '/client/normalClientInfo',
  //   name: 'normalClientInfo',
  //   component: () =>
  //     import('../pages/client/normalClientInfo')
  // },
  // {
  //   path: '/client/companyClient',
  //   name: 'companyClient',
  //   component: () =>
  //     import('../pages/client/companyClient')
  // },
  // {
  //   path: '/client/companyClientInfo',
  //   name: 'companyClientInfo',
  //   component: () =>
  //     import('../pages/client/companyClientInfo')
  // },
  // {
  //   path: '/product/productList',
  //   name: 'productList',
  //   component: () =>
  //     import('../pages/product/productList')
  // },
  // {
  //   path: '/product/editProductBase',
  //   name: 'editProductBase',
  //   component: () =>
  //     import('../pages/product/editProductBase')
  // },
  // {
  //   path: '/product/editProductDetail',
  //   name: 'editProductDetail',
  //   component: () =>
  //     import('../pages/product/editProductDetail')
  // },
  // {
  //   path: '/product/categoryList',
  //   name: 'categoryList',
  //   component: () =>
  //     import('../pages/product/categoryList')
  // },  {
  //   path: '/product/categoryParams',
  //   name: 'categoryParams',
  //   component: () =>
  //     import('../pages/product/categoryParams')
  // },
  // {
  //   path: '/product/productOrderList',
  //   name: 'productOrderList',
  //   component: () =>
  //     import('../pages/product/productOrderList')
  // },

  // {
  //   path: '/application',
  //   name: 'application',
  //   component: () =>
  //     import('../pages/application')
  // },

  // {
  //   path: '/books',
  //   name: 'books',
  //   component: () =>
  //     import('../pages/books')
  // },


 
]

const router = new VueRouter({
  routes
})

export default router
