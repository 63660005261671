import http from '../http';
let accoutApi

//获取用户绑定默认企业
const getUserDefaultMerchant = function () {
    const opts = {
        url: "/app/api/user/default/merchant/info",
        params: {},
        contentType: 'application/x-www-form-urlencoded',
        apiUrl: 'merchant'
    }
    return new Promise((resolve, reject) => {
        http.post(opts).then(res => {
            resolve(res);
        })
            .catch(err => {
                reject(err);
            })
    });
};
//获取用户信息
const getUserInfo = function () {
    const opts = {
        url: "/app/api/user/info",
        params: {},
        contentType: 'application/x-www-form-urlencoded',
        apiUrl: 'merchant'
    }
    return new Promise((resolve, reject) => {
        http.post(opts).then(res => {
            resolve(res);
        })
            .catch(err => {
                reject(err);
            })
    });
};
// 获取用户assecckey
const getUserAssecckeyList = function (pageNo, keywords) {
    const opts = {
        url: "/app/api/user/accessKey/list",
        params: {
            pageNo,
            keywords,
        },
        contentType: 'application/x-www-form-urlencoded',
        apiUrl: 'merchant'
    }
    return new Promise((resolve, reject) => {
        http.post(opts).then(res => {
            resolve(res);
        })
            .catch(err => {
                reject(err);
            })
    });
};
//编辑用户assecckey
const editUserAssecckey = function (editType, userAccessKeyId) {
    const opts = {
        url: "/app/api/user/accessKey/edit",
        params: {
            editType,
            userAccessKeyId,
        },
        contentType: 'application/x-www-form-urlencoded',
        apiUrl: 'merchant'
    }
    return new Promise((resolve, reject) => {
        http.post(opts).then(res => {
            resolve(res);
        })
            .catch(err => {
                reject(err);
            })
    });
};
// 编辑用户信息
const editBaseInfo = function (data) {
    const opts = {
        url: "/app/api/base/info/edit",
        params: {
            userNick: data.userNick,
            userCode: data.userCode,
            userImg: data.userImg,
            telephone: data.telephone,
        },
        contentType: 'application/x-www-form-urlencoded',
        apiUrl: 'merchant'
    }
    return new Promise((resolve, reject) => {
        http.post(opts).then(res => {
            resolve(res);
        })
            .catch(err => {
                reject(err);
            })
    });
};
//切换默认企业
const editDefaultMerchant = function (mechantCode) {
    const opts = {
        url: "/app/api/edit/user/default/merchant",
        params: {
            mechantCode,
        },
        contentType: 'application/x-www-form-urlencoded',
        apiUrl: 'merchant'
    }
    return new Promise((resolve, reject) => {
        http.post(opts).then(res => {
            resolve(res);
        })
            .catch(err => {
                reject(err);
            })
    });
};

//编辑用户实名信息
const editUserComplateInfo = function (data) {
    const opts = {
        url: "/app/api/user/complate/edit",
        params: {
            certificateCode: data.certificateCode,
            certificateImgA: data.certificateImgA,
            certificateImgB: data.certificateImgB,
            certificateType: data.certificateType,
            realName: data.realName
        },
        contentType: 'application/json',
        apiUrl: 'merchant'
    }
    return new Promise((resolve, reject) => {
        http.post(opts).then(res => {
            resolve(res);
        })
            .catch(err => {
                reject(err);
            })
    });
};

//获取个人详细资料
const getUserComplateInfo = function () {
    const opts = {
        url: "/app/api/user/complate/info",
        params: {

        },
        contentType: 'application/x-www-form-urlencoded',
        apiUrl: 'merchant'
    }
    return new Promise((resolve, reject) => {
        http.post(opts).then(res => {
            resolve(res);
        })
            .catch(err => {
                reject(err);
            })
    });
};
// 获取用户绑定企业列表
const getUserMerchantList = function () {
    const opts = {
        url: "/app/api/relation/merchant/list",
        params: {
            pageNo: 1
        },
        contentType: 'application/x-www-form-urlencoded',
        apiUrl: 'merchant'
    }
    return new Promise((resolve, reject) => {
        http.post(opts).then(res => {
            resolve(res);
        })
            .catch(err => {
                reject(err);
            })
    });
};
//编辑企业信息
const editCompany = function (data) {
    const opts = {
        url: "/app/api/user/merchant/edit?editType=" + data.editType,
        params: {
            merchantLogo: data.merchantLogo,
            merchantName: data.merchantName,
            businessLicense: data.businessLicense,
            businessLicenseImg: data.businessLicenseImg,
            bankAccountName: data.bankAccountName,
            bankAccount: data.bankAccount,
            addressDetail: data.addressDetail,
            editType: data.editType,
            merchantCode: data.merchantCode,
            merchantId: data.merchantId,
        },
        contentType: 'application/json',
        apiUrl: 'merchant'
    }
    return new Promise((resolve, reject) => {
        http.post(opts).then(res => {
            resolve(res);
        })
            .catch(err => {
                reject(err);
            })
    });
};
// 我的订单列表
const getUserOrderList = function (pageNo, orderSn, orderStatus) {
    const opts = {
        url: "/app/pc/api/user/platProduct/order/list",
        params: {
            pageNo,
            orderSn,
            orderStatus,
        },
        contentType: 'application/x-www-form-urlencoded',
        apiUrl: 'merchant'
    }
    return new Promise((resolve, reject) => {
        http.post(opts).then(res => {
            resolve(res);
        })
            .catch(err => {
                reject(err);
            })
    });
};
// 我的订单详情
const getUserOrderInfo = function (orderSn) {
    const opts = {
        url: "/app/pc/api/user/platProduct/order/info",
        params: {
            orderSn,
        },
        contentType: 'application/x-www-form-urlencoded',
        apiUrl: 'merchant'
    }
    return new Promise((resolve, reject) => {
        http.post(opts).then(res => {
            resolve(res);
        })
            .catch(err => {
                reject(err);
            })
    });
};
export default accoutApi = {
    getUserDefaultMerchant,
    getUserInfo,
    getUserAssecckeyList,
    editUserAssecckey,
    editBaseInfo,
    editDefaultMerchant,
    editUserComplateInfo,
    getUserComplateInfo,
    getUserMerchantList,
    editCompany,
    getUserOrderList,
    getUserOrderInfo
}